import React from "react";

function About() {
   return(
    <>
        <div className="container mt-4">
            <h3>About us</h3>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                 Cumque sed est commodi doloremque maxime optio explicabo,
                 blanditiis esse iste quis laboriosam.</p>
        </div>

    </>
   );
    
}
export default About;